import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'

import React, { useState } from 'react'
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import { Dashboard, useUppy } from '@uppy/react'
import Webcam from '@uppy/webcam'
import ImageEditor from '@uppy/image-editor'
import Compressor from '@uppy/compressor'
import PredictionRing from './ResultViz'
import { Stack } from '@mui/material'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';


function App() {
  const [predictions, setPredictions] = useState([{ 'name': '', 'percent': '-0.00001' }, { 'name': '', 'percent': '-0.00001' }, { 'name': '', 'percent': '-0.00001' }])

  const uppy = useUppy(() => {
    return new Uppy({
      autoProceed: false,
      restrictions: {
        allowedFileTypes: ["image/*"]
      },
      allowMultipleUploads: false
    }).use(Compressor, {
      checkOrientation: false,
      width: 500,
      height: 500,
    })
      .use(Webcam, {
        showVideoSourceDropdown: true,
        modes: ['picture']
      })
      .use(ImageEditor, {})
      .use(XHRUpload, {
        endpoint: 'https://predictapi.mushroom-id.app/predict',
      })
      .on('upload-success', (file, response) => {
        setPredictions(response.body)
      }).on('cancel-all', (file) => {
        setPredictions([{ 'name': '', 'percent': '-0.00001' }, { 'name': '', 'percent': '-0.00001' }, { 'name': '', 'percent': '-0.00001' }])
      })
  })

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const modal_box_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (<div style={{
    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
  }}>
    <h1>British mushroom classifier</h1>
    <p>
      This is an app for classifying mushrooms based on images of them. It's based on the (amazingly) open <a href="https://www.inaturalist.org/" target="_blank" rel="noreferrer">inaturalist</a>  <a href="https://github.com/inaturalist/inaturalist-open-data" target="_blank">dataset</a>.
    </p>
    <p>
      Obviously, only use this app to provide hints about what you've found, and don't eat anything you're not sure about.
    </p>
    <p>
      Happy hunting.
    </p>

    <Button onClick={handleOpen}><h3>About and guidelines</h3></Button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modal_box_style}>
        <h2>How to use</h2>
        <p>
          The app is intended to provide <b>hints</b> about where to look for more information, rather than a definitive answer (especially when it comes to edibility and/or mind-altering properties).
        </p>
        <p>
          The model is aware of just over 1, 000 species of mushroom found in the UK, which will cover most of the toadstool-like species you're likely to find. However, there's a chance you've found something that the model is not aware of (especially lichens, rust fungi, jelly fungi, cup fungi, morels and truffles), in which case it will give you a completely incorrect identificaiton.
        </p>
        <p>
          The model is <b>moderately</b> accurate -- the exact species will appear in the top 3 results about <b>80%</b> of the time, but for more common and/or more distinctive mushrooms this will be higher. The quality of the predictions is also dependent on the <b>quality of the image</b>.
        </p>
        <p>
          The percentages output by the model are a reasonable indication of how confident the model is in its prediction, but they are not a perfect measure of confidence.
        </p>
        <p>
          Even a perfectly calibrated 95% level of confidence is <b>too low</b> to risk serious harm if you don't have <b>information from other sources</b> to back up the ID and <b>assess the risk associated with getting it wrong</b>.
        </p>
      </Box>
    </Modal>

    <Dashboard
      uppy={uppy}
      plugins={['Webcam', 'ImageEditor']}
      proudlyDisplayPoweredByUppy={false}
      showProgressDetails={true}
      hideUploadButton={false}
      allowMultipleUploads={false}
      theme='dark'
      sx={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      }}
    />

    <Stack direction='row' sx={{
      display: predictions[0].percent > 0 ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center',
    }}>
      <PredictionRing name={predictions[0].name} percent={predictions[0].percent * 100} />
      <PredictionRing name={predictions[1].name} percent={predictions[1].percent * 100} />
      <PredictionRing name={predictions[2].name} percent={predictions[2].percent * 100} />
    </Stack>
  </div >)
}

export default App;
