import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { deepOrange } from "@mui/material/colors";
import { Stack } from "@mui/material";
import wikipediaCurIds from "./species_cur_ids.json";

const wikiBaseUrl = "https://en.wikipedia.org/?curid="



function GetWikiLink(name) {
    return wikiBaseUrl + wikipediaCurIds[name]
}


function CircularProgressWithLabel(props) {
    const colorCode = Math.max(Math.floor((props.value * 9) / 100) * 100, 50);
    const theme = createTheme({
        palette: {
            primary: {
                main: deepOrange[colorCode]
            }
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{
                position: "relative", display: "flex", alignItems: "center", justifyContent: "center"
            }}>
                <CircularProgress
                    variant="determinate"
                    value={props.value}
                    color="primary"
                    size={50}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >
                        {`${Math.round(props.percent)}%`}
                    </Typography>
                </Box>
            </Box>
        </ThemeProvider>
    );
}


export default function PredictionRing(props) {
    const [progress, setProgress] = React.useState(0);
    const wikiUrl = GetWikiLink(props.name)

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) =>
                prevProgress >= props.percent ? props.percent : prevProgress + 1
            );
        }, 100 / props.percent);
        return () => {
            clearInterval(timer);
        };
    }, [props.percent]);

    const buttonTheme = createTheme({
        typography: {
            button: {
                textTransform: 'none'
            }
        }
    });

    return <Stack>
        <ThemeProvider theme={buttonTheme}>
            <Button href={wikiUrl} target="_blank">{props.name}</Button>
        </ThemeProvider>
        < CircularProgressWithLabel value={progress} percent={props.percent} name={props.name} />
    </Stack>
}
